<template>
  <div>
    <div style="margin-bottom:15px;display:flex;justify-content:space-between;align-items:center;">
      <label class="label">Add contact info to your page</label>
      <div v-if="landing.current_lang !== landing.advanced.default_lang">
        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                  style="cursor: pointer" @click="openCopy"
              >Copy content from {{landing.advanced.default_lang}}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="socials">
      <div
        style="margin-bottom: 30px">
      <Editor
        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
        :init="options"
        v-model="content.text"
        @change="setMenu"
      >
      </Editor>
    </div>
      <div class="socials__item" v-for="item in content.content" :key="item.id">
        <div
          class="socials__btn"
          :class="{ active: item.active }"
          @click="addSocial(content.content, item.id)"
        >
          <span
            class="socials__icon"
            :class="{ active: item.active }"
            v-html="item.icon"
          ></span>
          {{ item.text }}
        </div>
        <div class="socials__link" v-if="item.active">
          <v-text-field
            outlined
            solo
            flat
            dense
            hide-details
            placeholder="Insert link"
            v-model="item.link"
            @input="setMenu"
          ></v-text-field>
        </div>
      </div>
    </div>

    <div class="constructor__main">
      <div class="constructor__header">
      </div>
      <div class="form">
        <div class="field">
          <label class="label">{{
            $t('constructor["Button Arrangement"]')
          }}</label>
          <p>
            {{ $t('constructor["Choose a style for the social links"]') }}
          </p>
          <div class="socials-style">
            <div
              class="
                                        socials-style__item
                                        socials-style__item_default
                                      "
              :class="{
                active: content.style === 'default',
              }"
              @click="changeStyleContact(content, 'default')"
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div
              class="
                                        socials-style__item
                                        socials-style__item_tile
                                      "
              :class="{
                active: content.style === 'tile',
              }"
              @click="changeStyleContact(content, 'tile')"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label"
            >{{ $t('constructor["Button Radius"]') }}
            <strong>{{ content.radius }}px</strong></label
          >
          <p>
            {{ $t('constructor["Choose your social links style"]') }}
          </p>
          <div class="slider">
            <v-slider
              :max="32"
              :min="0"
              v-model="content.radius"
              @end="setMenu"
            ></v-slider>
          </div>
        </div>
        <div class="field" v-if="content.style === 'default'">
          <label class="label"
            >Button Width <strong>{{ content.width }}%</strong></label
          >
          <div class="slider">
            <v-slider
              :max="100"
              :min="0"
              v-model="content.width"
              @end="setMenu"
            ></v-slider>
          </div>
        </div>
      </div>
    </div>
    <Editor
      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
      :init="options"
      v-model="content.second_text"
      @change="setMenu"
      style="margin-bottom: 15px"
    >
    </Editor>

    <div class="field">
      <div
        class="control"
        style="
                                  display: flex;
                                  justify-content: space-between;
                                "
      >
        <label class="label" style="display: flex; align-items: center"
          >Enable directions button</label
        >
        <v-switch v-model="content.map.enableButton"></v-switch>
      </div>
    </div>
    <div v-if="content.map.enableButton">
      <div
        style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
        class="field"
      >
        <label>Longitude</label>
        <v-text-field
          dense
          hide-details
          outlined
          style="max-width: 200px"
          v-model="content.map.lng_btn"
          @input="content.map.lng = content.map.lng_btn"
        ></v-text-field>
      </div>
      <div
        style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
        class="field"
      >
        <label>Latitude</label>
        <v-text-field
          dense
          hide-details
          outlined
          style="max-width: 200px"
          v-model="content.map.lat_btn"
          @input="content.map.lat = content.map.lat_btn"
        ></v-text-field>
      </div>
    </div>
    <div class="field">
      <div
        class="control"
        style="
                                  display: flex;
                                  justify-content: space-between;
                                "
      >
        <label class="label" style="display: flex; align-items: center"
          >Enable Map</label
        >
        <v-switch v-model="content.map.show"></v-switch>
      </div>
    </div>
    <div v-if="content.map.show">
      <div
        style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
        class="field"
      >
        <label>Longitude</label>
        <v-text-field
          dense
          hide-details
          outlined
          style="max-width: 200px"
          v-model="content.map.lng"
          @input="content.map.lng_btn = content.map.lng"
        ></v-text-field>
      </div>
      <div
        style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
        class="field"
      >
        <label>Latitude</label>
        <v-text-field
          dense
          hide-details
          outlined
          style="max-width: 200px"
          v-model="content.map.lat"
          @input="content.map.lat_btn = content.map.lat"
        ></v-text-field>
      </div>
      <div class="field">
        <label>Change zoom range {{ content.map.zoom }}</label>
        <v-slider
          :max="18"
          :min="0"
          hide-details
          v-model="content.map.zoom"
          @end="setMenu"
        ></v-slider>
      </div>
      <div class="field" style="margin-bottom: 15px">
        <div
          class="control"
          style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
        >
          <label class="label" style="display: flex; align-items: center"
            >Enable zoom control</label
          >
          <v-switch v-model="content.map.zoomControl"></v-switch>
        </div>
      </div>
      <div class="field" style="margin-bottom: 15px">
        <div
          class="control"
          style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
        >
          <label class="label" style="display: flex; align-items: center"
            >Enable fullscreen control</label
          >
          <v-switch v-model="content.map.fullscreenControl"></v-switch>
        </div>
      </div>
      <div class="field" style="margin-bottom: 15px">
        <div
          class="control"
          style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
        >
          <label class="label" style="display: flex; align-items: center"
            >Enable street view control</label
          >
          <v-switch v-model="content.map.streetViewControl"></v-switch>
        </div>
      </div>
      <div class="field" style="margin-bottom: 15px">
        <div
          class="control"
          style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
        >
          <label class="label" style="display: flex; align-items: center"
            >Enable map type control</label
          >
          <v-switch v-model="content.map.mapTypeControl"></v-switch>
        </div>
      </div>
    </div>
    <SliderMenu
      :slider_menu="content.slider"
      :active_slider="content"
      :menu="menu"
    />
  <PopupConfirm ref="PopupConfirm"  @confirm="confirmCopy" :title="`Are you sure about copy content from ${defaultLangName} to ${currentLangName}`"/>
  </div>
</template>

<script>
import SliderMenu from "./SliderMenu";
import { mapState } from "vuex";

import PopupConfirm from "@/components/Popup/PopupConfirm.vue"
export default {
  name: "typeContact",
  props: ["content",'item'],
  components: {

    PopupConfirm,
    SliderMenu,
  },
  data() {
    return {
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 400,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_rel',
                                  label: 'Rel',
                                  items: [
                                      {text: 'No value', value: ''},
                                      {text: 'Alternate', value: 'alternate'},
                                      {text: 'Help', value: 'help'},
                                      {text: 'Manifest', value: 'manifest'},
                                      {text: 'No follow', value: 'nofollow'},
                                      {text: 'No opener', value: 'noopener'},
                                      {text: 'No referrer', value: 'noreferrer'},
                                      {text: 'Opener', value: 'opener'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
    };
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("landing", ["menu"]),
    currentLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.current_lang).name
    },
    defaultLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.advanced.default_lang).name
    },
  },
  methods: {
    confirmCopy(){
      this.item.content[this.landing.current_lang][6] = JSON.parse(JSON.stringify(this.item.content[this.landing.advanced.default_lang][6]));
      this.forceRerender();
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    openCopy(){
      this.$refs.PopupConfirm.open();
    },
    changeStyleContact(content, style) {
      content.style = style;
      this.setMenu();
    },
    setMenu() {
      this.$store.commit("landing/setMenu", this.menu);
    },
    addSocial(arr, id) {
      arr.forEach((social) => {
        if (social.id === id) {
          social.active = !social.active;
        }
      });
      this.setMenu();
    },
  },
};
</script>

<style lang="scss" scoped></style>
